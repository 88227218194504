<template>
    <div class="component-wrap order_item-wrap" @click="onClick">
        <div class="order_item-header">
            <van-cell class="order_item-cell" center>
                <template #icon>
                    <!-- <van-image :src="require('@/assets/images/user/user_order-movie3.png')" width="24" height="24" style="margin-right:4px;"></van-image> -->
                    <van-icon :name="require('@/assets/images/user/user_order-movie3.png')" size="24" style="margin-right: 4px;"></van-icon>
                </template>
                <template #title>
                    <span class="fw-7">电影票</span>
                    <!-- <span>{{order.id}}</span> -->
                </template>
                <div class="fc-status">{{order.orderStatusText}}</div>
            </van-cell>
        </div>
        <div class="order_item-body fx-column">
            <div class="fx-row">
                <div class="order_item-logo">
                    <van-image :src="order.logo + '_180x180'" width="90" height="120"></van-image>
                </div>
                <div class="order_item-info fx-column fx-between">
                    <div>
                        <span class="order_item-name">{{order.movieName}}</span>
                        <span style="margin-left: 5px">{{order.quantity}}张</span>
                    </div>
                    <div class="fc-tips">
                        <span>{{$momentFormatter(order.playTime, 'YYYY-MM-DD HH:mm')}}</span>
                        <span> ({{order.edition}})</span>
                    </div>
                    <span class="fc-tips">{{order.cinemaName}}</span>
                    <span class="fc-tips">{{order.hallName}}</span>
                    <span class="fc-tips">{{order.seats}}</span>
                </div>
            </div>
            <div class="order_item-amount fx-column fx-end">
                <div>
                    <span class="amount">总金额：</span>
                    <span v-html="decimalPoint(new String(order.amount))"></span>
                </div>
            </div>
        </div>
        <div class="order_item-footer fx-row fx-between">
            <div class="fx-middle">
                <div v-if="order.remainTime" class="fx-row fx-center">
                    <van-count-down ref="countDown" :time="order.remainTime" :auto-start="false" format="mm:ss" style="font-size: 1em;" class="fc-status fx-row fx-center" @finish="finish"></van-count-down>
                    <span class="fc-status">后自动取消订单</span>
                </div>
            </div>
            <div>
                <div v-if="order.remainTime" class="fx-row fx-around">
                    <van-button round size="mini" class="btn-mini" :color="$config.style.cancelColor" @click="$emit('on-cancel', order.id)">取消订单</van-button>
                    <van-button round size="mini" class="btn-mini" :color="$config.style.statusColor" @click="$emit('on-pay', order.id)">去支付</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { decimalPoint } from '@/libs/common-utils/common-utils';
export default {
    model: {
        prop: 'order',
        event: 'update',
    },
    props: {
        order: Object,
    },
    computed: {
    },
    mounted() {
        this.$nextTick(() => {
            if (this.order.remainTime) {
                setTimeout(() => {
                    this.$refs.countDown.start();
                }, 0);
            }
        })
    },
    methods: {
        decimalPoint,
        onClick() {
            if (this.order.orderStatus == 'P' || this.order.orderStatus == 'S' || this.order.orderStatus == 'R' || this.order.orderStatus == 'T') {
                this.$emit('on-click', this.order.id);
            }
        },
        finish() {
            this.$emit('count-down-finish', this.order);
        }
    },
}
</script>

<style lang="scss" scoped>
.order_item-wrap {
	background-color: #fff;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 1px 1px #DCDCDC;
	margin-bottom: 8px;
	/deep/ .van-cell {
		padding: 6px 8px;
	}
	.order_item-body {
		padding: 10px;
		.order_item-info {
			margin-left: 8px;
			.order_item-name {
				font-size: 16px;
			}
		}
		.order_item-amount {
			font-size: 14px;
		}
	}
	.order_item-footer {
		padding: 0px 10px;
		> div {
			> div {
				padding-top: 4px;
				padding-bottom: 12px;
			}
		}
		.btn-mini {
			margin-left: 10px;
		}
	}
}
</style>
